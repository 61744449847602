<template>
  <div class="contacts">
    <div class="first">
      <img class="img"  src="@/assets/contacts/contacts1.png" alt="Mantra">
    </div>
    <div class="second">
      <img class="img" src="@/assets/contacts/contacts2.png" alt="Mantra">
    </div>
    <ul class="info">
      <InfoItem 
        v-for="item in items"
        :key="item.id"
        :item="item"
      />
    </ul>
  </div>
</template>

<script>
import InfoItem from './InfoItem'

export default {
  name: 'Contacts',
  components: {
    InfoItem
  },
  data() {
    return {
      items: [
        {
          id: 1,
          src: 'phone',
          title: 'Горячая линия',
          text: '8-800-201-66-39',
          tel: true,
          href: 'tel: 8-800-201-66-39'
        },
        {
          id: 2,
          src: 'email',
          title: 'E-Mail поддержка',
          text: 'support@themantra.shop',
          href: 'mailto: support@themantra.shop'
        },
        {
          id: 3,
          src: 'email',
          title: 'Письмо руководству',
          text: 'boss@themantra.shop',
          href: 'mailto: boss@themantra.shop'
        },
        {
          id: 4,
          src: 'whatsapp',
          title: 'Whatsapp чат',
          text: '+7 901 000-19-02',
          href: 'https://wa.me/79010001902'
        },
        {
          id: 5,
          src: 'fingerprint',
          title: {
            text: 'ОГРН: ',
            number: 319619600087007
          },
          content: {
            text: 'ИНН: ',
            number: '610109143881'
          },
          ogrn: true,
        },
        {
          id: 6,
          src: 'location',
          title: 'Ростов-на-Дону, ул. Серафимовича 79',
          location: true
        },
      ]
    }
  }
}
</script>

<style scoped lang="scss">

.img {
  width: 100%;
}

.first {
  grid-area: first;
}

.second {
  grid-area: second;
  padding-right: 50px;
  border-right: 2px solid grey;

}

.info {
  grid-area: info;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  // @media screen and (max-width: 875px) {
  //   align-items: center;
  // }

}

.contacts {
  display: grid;
  grid-template-areas: 
            '. first first first .'
            '. second . info .';
  grid-template-columns: 5% auto 3% auto 1%;
  row-gap: 40px;
}

@media screen and (max-width: 875px) {

  .second {
    display: none;
  }
  
  .contacts {
    grid-template-areas: 
          '. first first first .'
          '. info info info .';
  }
}

</style>