<template>
  <li class="item">
    <a :href="item.href">
      <img class="item--img" :src="require(`@/assets/contacts/icons/${item.src}.svg`)" alt="section icon">
    </a>

    <div class="item--content" v-if="!item.ogrn">
      <p class="item--title" :class="{ location: item.location}">
        {{ item.title }}
      </p>
      
      <a class="item--text item--text-link" :href="item.href">
        {{ item.text }}
      </a>
    </div>

    <div class="item--content" v-else>
      <p>
        <span class="ogrn-text">
          {{ item.title.text }}
        </span>
        <span class="ogrn-number">
          {{ item.title.number }}
        </span>
      </p>
      <p class="iin">
        <span class="ogrn-text">
          {{ item.content.text }}
        </span>
        <span class="ogrn-number">
          {{ item.content.number }}
        </span>
      </p>
    </div>
  </li>
</template>

<script>
export default {
  name: 'InfoItem',
  props: {
    item: {
      type: Object,
      required: true,
    }
  },
}
</script>

<style scoped lang="scss">

.item {
  display: flex;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.05rem;

  &--img {
    margin-right: 15px;
  }

  &--title {
    font-size: 13px;
  }

  &--text {
    font-size: 18px;
    font-weight: 500;

    &-link:hover {
      border-bottom: 2px solid #fff;
      margin-bottom: -2px;
    }
  }

  &--content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  

  @media screen and (max-width: 875px) {
    margin-bottom: 14px;
  }

}

.grey {
  color: #c2c2c2;
}

.location {
  font-weight: 600;
  text-transform: uppercase;
}

.ogrn-text {
  font-size: 13px;
  font-weight: 600;
}

.ogrn-number {
  font-weight: 300;
  font-size: 12px;
}
</style>